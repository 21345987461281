// @flow
import React, { useState, useContext, useMemo } from 'react'
import { AuthContext } from 'contexts/auth'
import { FEATURE_RESOURCE_URL } from 'qap/constants'

import { useRemoteResource } from './store/useRemoteResource'
import { getNewArticleIds } from './helper'
import ViewArticles from './views'

type Props = {
  styles: Object
}

const lastReadKey = 'FeatureNotificationsLastReadAt'

const FeatureNotification = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { user: { lastSignedInAt } } = useContext(AuthContext)
  const { loading, data, errors } = useRemoteResource({ url: FEATURE_RESOURCE_URL })

  const lastReadAt = localStorage.getItem(lastReadKey) || ''
  const newArticleIds = useMemo(() => getNewArticleIds({ data, lastSignedInAt, lastReadAt }), [data, lastSignedInAt, lastReadAt])

  if (errors) {
    return null
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    localStorage.setItem(lastReadKey, new Date().toISOString())
  }

  return (
    <ViewArticles
      articles={data}
      newArticleIds={newArticleIds}
      onOpen={handleClick}
      onClose={handleClose}
      anchorEl={anchorEl}
      styles={props.styles}
      loading={loading}
    />
  )
}

export default FeatureNotification
