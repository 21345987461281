// @flow
import React from 'react'
import classnames from 'classnames'
import { decode } from 'html-entities'
import { List, ListItem } from '@mui/material'

import ProgressIndicator from 'components/molecules/progressIndicator'
import ExternalLink from 'components/atoms/externalLink'

import type { Article } from '../types'

type ShowProps = {
  article: Article,
  isNew: boolean,
  classes: Object
}

const ShowArticle = ({ classes, article, isNew }: ShowProps) => {
  const { link, title, excerpt } = article

  return (
    <ExternalLink to={link} className={classnames({
      [classes.articleIsNew]: isNew,
      [classes.articleLink]: true
    })}>
      {decode((excerpt || title).rendered)}
    </ExternalLink>
  )
}

type ListProps = {
  loading: boolean,
  articles: Article[],
  newArticleIds: string[],
  classes: Object
}

const ListArticles = ({ loading, articles, newArticleIds, classes }: ListProps) => {
  if (loading) {
    return <ProgressIndicator size={20} show className={classes.progressIndicator} />
  }

  return (
    <List dense className={classes.articleList}>
      {articles.map((article, i) => (
        <ListItem key={i} className={classes.article}>
          <ShowArticle article={article} isNew={newArticleIds.includes(article.id)} classes={classes} />
        </ListItem>
      ))}
    </List>
  )
}

export default ListArticles
