// @flow
import * as Sentry from '@sentry/browser'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './rootReducer'
import * as rootActions from './rootActions'
import rootSaga from './rootSaga'
import StateLoader from 'utilities/stateLoader'
import { SENTRY_DSN } from 'qap/constants'

const sagaMiddleware = createSagaMiddleware({
  onError: SENTRY_DSN ? Sentry.captureException : () => {}
})

const store = createStore(
  rootReducer,
  { auth: StateLoader.getAuth() },
  composeWithDevTools(applyMiddleware(
    sagaMiddleware
  ))
)

sagaMiddleware.run(rootSaga)

store.subscribe(() => {
  StateLoader.saveAuth(store.getState().auth)
})

const { auth } = store.getState()
store.dispatch(rootActions.appInitialization(auth))

export default store
