// @flow
import type { State, Action } from './type'

export const types = {
  FETCH_RESOURCE_INIT: 'FETCH_RESOURCE_INIT',
  FETCH_RESOURCE_SUCCESS: 'FETCH_RESOURCE_SUCCESS',
  FETCH_RESOURCE_FAILURE: 'FETCH_RESOURCE_FAILURE'
}

export const initalState = {
  loading: false,
  data: [],
  errors: null
}

export const remoteResourceReducer = (state: State, action: Action) => {
  switch (action.type) {
    case types.FETCH_RESOURCE_INIT:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      }
    case types.FETCH_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors
      }

    default:
      return state
  }
}
