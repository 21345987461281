// @flow
import { Article } from './types'

type LastUsedTimes = {
  lastSignedInAt: string,
  lastReadAt: string
}

type isArticleNewProps = {
  modifiedAt: string
} & LastUsedTimes

const isArticleNew = ({ modifiedAt, lastSignedInAt, lastReadAt }: isArticleNewProps) => (modifiedAt >= lastSignedInAt) && (modifiedAt >= lastReadAt)

type getNewArticleIdsProps = {
  data: Article[],
} & LastUsedTimes

export const getNewArticleIds = ({ data, lastSignedInAt, lastReadAt }: getNewArticleIdsProps) => (
  data.filter(article => isArticleNew({
    modifiedAt: article.modified_gmt,
    lastSignedInAt,
    lastReadAt
  })).map(article => article.id): string[]
)
