import QAPVars from 'qap/vars'

export const styles = theme => ({
  title: {
    margin: theme.spacing(2, 2, 1, 2)
  },
  badge: {
    width: '1rem',
    height: '1rem',
    color: QAPVars.white,
    backgroundColor: QAPVars.pRed
  },
  badgeContainer: {
    padding: theme.spacing(0, 0.8)
  },
  progressIndicator: {
    margin: theme.spacing(2)
  },
  articleList: {
    maxWidth: '400px'
  },
  article: {
    borderTop: `1px solid ${QAPVars.borderGray}`,
    '&:first-child': {
      borderColor: QAPVars.pGray
    }
  },
  articleIsNew: {
    fontWeight: 'bold'
  },
  articleLink: {
    color: QAPVars.linkBlue,
    textDecoration: 'none',
    padding: theme.spacing(1, 0)
  }
})
