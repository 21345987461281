// @flow
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ExternalLink from 'components/atoms/externalLink'
import { salesforcePortalTabsActions } from 'state/salesforcePortalTabs'
import { getClassicPortalFrontdoorAuthUrl } from './helpers'
import { SF_PORTAL_AUTH_REDIRECT_URL } from 'qap/constants'

type Props = {
  redirectUrl?: string
}

const ClassicPortalLink = React.forwardRef<Props, HTMLElement>((props: Props, ref: Object) => {
  const portalFrontdoorUrl = useSelector(state => state.salesforcePortalTabs.loginUrl)
  const dispatch = useDispatch()
  const { redirectUrl = SF_PORTAL_AUTH_REDIRECT_URL, ...others } = props

  useEffect(() => {
    dispatch(salesforcePortalTabsActions.fetchSalesforcePortalLoginUrl())
  }, [])

  // Always return Link component to avoid MUI component loading error:
  // The `component` prop provided to ButtonBase is invalid. Please make sure the children prop is rendered in this custom component.
  // if(!portalFrontdoorUrl) return null
  return (
    <ExternalLink
      to={getClassicPortalFrontdoorAuthUrl({
        frontdoorUrl: portalFrontdoorUrl,
        redirectUrl
      })}
      ref={ref}
      {...others}
    />
  )
})

export default ClassicPortalLink
