// @flow
import qs from 'qs'

import { TABS } from 'components/pages/userPreferencePage/userPreferenceTabs'
import {
  USER_CALENDAR_PREFERENCE_URL,
  SF_PORTAL_FULL_ACCESS
} from 'qap/constants'

export const stringifyCalendarLink = () => (
  `${USER_CALENDAR_PREFERENCE_URL}?${qs.stringify({ tabFilter: TABS[0].value })}`
)

export const showClassicPortalMenu = () => (SF_PORTAL_FULL_ACCESS && SF_PORTAL_FULL_ACCESS.toLowerCase() === 'true')
