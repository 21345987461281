// @flow
import * as Sentry from '@sentry/browser'

type Options = {
  body?: Object,
  method?: string,
  token?: string
}

const logError = (error, url) => {
  Sentry.captureException(error, { extra: { url } })
}

const organisationIdStr = () => {
  const organisationIds = localStorage.getItem('organisation_ids')
  return organisationIds ? `organisation_ids=${organisationIds}` : ''
}

const authStr = (token) => token ? `Bearer ${token} ${organisationIdStr()}` : ''

export const fetch = (url: string, { body, method, token }: Options) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${authStr(token)}`,
      'Accept': 'application/json'
    },
    method: method || 'GET',
    body: body && JSON.stringify(body)
  }
  try {
    return window
      .fetch(url, options)
      .then(async (res) => {
        const { ok, status, statusText } = res
        const json = await res.json()
        let response = {
          status: status.toString(),
          statusText,
          ok,
          body: json
        }
        return response
      })
      .catch(e => {
        logError(e, url)
        throw e
      })
  } catch (e) {
    logError(e, url)
    throw e
  }
}
