// @flow
import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import DefaultErrorBoundary from './views/defaultBoundary'
import ContentErrorBoundary from './views/contentBoundary'
import DisplayStackTrace from './views/displayStackTrace'

import type { ErrorComponentInterface } from './views/types'
import type { State, Props, ErrorStack } from './types'

export { DisplayStackTrace, ContentErrorBoundary }
export type { ErrorComponentInterface }

class ErrorBoundary extends Component<Props, State> {
  constructor (props: any) {
    super(props)
    this.state = {
      hasError: false,
      errors: null
    }
  }

  // https://reactjs.org/docs/error-boundaries.html
  componentDidCatch (error: Error, info: ErrorStack) {
    const prod = process.env.NODE_ENV === 'production'

    if (this.props.devOnly && prod) {
      // We don't want to render this boundary in production
      throw new Error(error)
    }

    this.setState({
      hasError: true,
      errors: {
        message: error.message,
        name: error.name,
        componentStack: info.componentStack
      }
    })

    if (prod) {
      // Send the error stack to Sentry
      // https://blog.sentry.io/2017/09/28/react-16-error-boundaries
      Sentry.captureException(error, { extra: info })
    }
  }

  render () {
    const { hasError, errors } = this.state
    const { children, classes, component } = this.props

    if (hasError) {
      return React.createElement(component || DefaultErrorBoundary, { classes, errors })
    }

    return children || null // To prevent "Nothing was returned from render" error
  }
}

export default ErrorBoundary
