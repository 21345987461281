import React from 'react'
import { Popover, IconButton, Typography, Badge } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import NotificationsIcon from '@mui/icons-material/Notifications'

import ListArticles from './list'
import { styles } from './styles'

const ViewArticles = ({ classes, styles, articles, newArticleIds, onOpen, onClose, anchorEl, loading }) => {
  const open = Boolean(anchorEl)
  const id = open ? 'recently-released-features' : undefined

  return (
    <div className={styles.notificationContainer}>
      <IconButton aria-describedby={id} variant='contained' onClick={onOpen} size="large">
        <Badge
          badgeContent={newArticleIds.length}
          max={10}
          overlap='circular'
          className={classes.badgeContainer}
          classes={{ badge: classes.badge }}>
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Typography variant='h2' className={classes.title}>Recently Released Features</Typography>
        {
          open && <ListArticles
            articles={articles}
            newArticleIds={newArticleIds}
            loading={loading}
            classes={classes}
          />
        }
      </Popover>
    </div>
  )
}

export default withStyles(styles)(ViewArticles)
