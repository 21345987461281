// @flow
import { useEffect, useReducer } from 'react'

import { types, initalState, remoteResourceReducer } from './store'
import type { UseRemoteResourceParams } from './type'

export const useRemoteResource = ({ url }: UseRemoteResourceParams) => {
  const [state, dispatch] = useReducer(remoteResourceReducer, initalState)

  useEffect(() => {
    const fetchResource = async () => {
      dispatch({ type: types.FETCH_RESOURCE_INIT })

      try {
        const response = await fetch(url)
        const data = await response.json()
        dispatch({ type: types.FETCH_RESOURCE_SUCCESS, data })
      } catch (errors) {
        dispatch({ type: types.FETCH_RESOURCE_FAILURE, errors })
      }
    }

    if (url) fetchResource()
  }, [url])

  return state
}
